var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"value":_vm.value,"max-width":"1200px","persistent":""}},[_c('v-card',{staticClass:"d-flex flex-column"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.getTitle)),_c('br'),_vm._v(" "+_vm._s(_vm.getSubTitulo)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),(_vm.value)?_c('v-card-text',{staticClass:"py-4 flex-grow-1"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"px-3"},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-light"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('lookup-dominio',{attrs:{"label":"* Tipo do Anexo","dense":"","hint":"Selecione o Tipo do Anexo","type":_vm.lookups.arrTipoAnexo,"hidden-clear":true,"rules":[
                                        this.$validators.string.required
                                    ]},model:{value:(_vm.anexo.tipoAnexo),callback:function ($$v) {_vm.$set(_vm.anexo, "tipoAnexo", $$v)},expression:"anexo.tipoAnexo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-file-input',{attrs:{"sense":"","accept":".pdf","label":"Escolha um arquivo PDF de até 20MB","rules":[
                              this.$validators.string.required,
                              function (v) { return this$1.$validators.number.tamanhoMaxArquivo(v, 20); }
                          ]},model:{value:(_vm.anexo.arquivo),callback:function ($$v) {_vm.$set(_vm.anexo, "arquivo", $$v)},expression:"anexo.arquivo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.formValid || _vm.processing},on:{"click":_vm.save}},[_vm._v("Inserir")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-3",attrs:{"loading":_vm.loading}},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-light"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.listaAnexos),function(anexo){return _c('v-chip',{key:anexo.id,staticClass:"ma-2",attrs:{"color":_vm.defineCor(anexo.tipoAnexo),"text-color":"white","label":""}},[_c('span',{staticClass:"v-chip__content"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-floppy")]),_vm._v(" "+_vm._s(anexo.descTipoAnexo)+" - "+_vm._s(anexo.arquivo)+" "),_c('v-icon',{attrs:{"small":"","right":""},on:{"click":function($event){return _vm.visualizar(anexo.arquivo)}}},[_vm._v("mdi-eye-arrow-right-outline")]),(_vm.permiteExcluir)?_c('v-icon',{attrs:{"small":"","right":""},on:{"click":function($event){return _vm.remover(anexo.id, anexo.arquivo)}}},[_vm._v("mdi-trash-can-outline")]):_vm._e()],1)])}),1)],1)],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("Fechar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }