<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12">
          <strong>Detalhamento dos valores </strong> 
          <v-btn small color="secondary" @click="addItem()">
            <v-icon>mdi-playlist-plus</v-icon>Add
          </v-btn>
        </v-col>
      </v-row>
      <v-divider/>
    </v-col>
    <v-col cols="12">
      <v-row v-for="(item, index) in value.itens" :key="index">
        <v-col cols="12" sm="2">
          <lookup-dominio label="* Tipo"
                          :hidden-clear="true"
                          hint="Selecione o Tipo"
                          v-model="item.idTipo"
                          :type="lookups.tipoItemContrato"
                          />
        </v-col> 
        <v-col cols="12" md="4"><!-- " -->
          <search-box :ref="'comboItemContrato_' + index"
              label="* Item"
              popup-label="Selecione um Item"
              :id.sync="item.idItemContrato"
              :descricao.sync="item.descricaoItemContrato"
              :hidden-clear="true"
              :value="itemContrato(index)"
              :loader-fn="loaderItemContrato"
              item-key="id"
              item-text="descricao"
              >
            <template v-slot:empty>
              <p class="ma-2">
                Nenhum Item encontrado
              </p>
            </template>
          </search-box>
        </v-col>
        <v-col cols="12" sm="1">
            <v-text-field dense
                label="Quantidade"
                v-model="item.quantidade" 
                type="number"/>
        </v-col>
        <v-col cols="12" sm="2">
            <v-text-field dense
                label="Valor Unitário Mensal"
                prefix="R$"
                v-model="item.valUnitMensal" type="number"/>
        </v-col>
        <v-col cols="12" sm="2">
            <v-text-field dense
                label="Valor Unitário Global"
                prefix="R$"
                v-model="item.valUnitGlobal" type="number"/>
        </v-col>
        <v-col cols="12" sm="1">
          <v-btn small color="error" @click="removeItem(index)">
            <v-icon>mdi-playlist-remove</v-icon>
          </v-btn>                         
        </v-col>                           
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row v-show="detalhouValor">
        <v-col cols="12">
          <strong>Totais</strong> 
          <v-divider/>
        </v-col>
        <v-col cols="12" sm="4">
          <strong>Total do Valor Mensal dos itens: </strong>{{totalValorMensalItens.toFixed(2)}}
        </v-col>
        <v-col cols="12" sm="4">
          <strong>Total do Valor Global dos itens: </strong>{{totalValorGlobalItens.toFixed(2)}}
        </v-col>
        <v-col cols="12" sm="4" v-show="validarValores !== ''">
          <span class="red--text" v-html="validarValores"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import {findAll as findAllItemContrato} from "../../../api/itemContrato";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";

export default {
  name: "DetalharItemForm",
  components: {SearchBox, LookupDominio},
  props: {
    value: {
      type: Object
    },
    titulo: String,
  },
  data() {
    return {
      lookups: {
        tipoItemContrato: ItemDeDominio.TIPO_ITEM_CONTRATO,
      },
      watchers: [],
    }
  },
  computed: {  
    detalhouValor(){
      return this.value.itens?.length > 0;
    },
    totalValorMensalItens(){
      return  parseFloat(
                (this.value.itens || [])
                      .reduce(
                        (acumulador, obj) => parseFloat(acumulador) + (parseFloat(obj.valUnitMensal) * parseInt(obj.quantidade))
                      , 0)
              );
    },
    totalValorGlobalItens(){
      return  parseFloat(
                (this.value.itens || [])
                      .reduce(
                        (acumulador, obj) => parseFloat(acumulador) + (parseFloat(obj.valUnitGlobal) * parseInt(obj.quantidade))
                      , 0)
              );
    },
    validarValores(){
      let textoFinal = "";
      let valorMensal = parseFloat(this.value.valorMensal) > 0 ? parseFloat(this.value.valorMensal) : 0;
      let valorGlobal = parseFloat(this.value.valorGlobal) > 0 ? parseFloat(this.value.valorGlobal) : 0;

      textoFinal += (this.totalValorMensalItens > valorMensal ? "Total do Valor <b>Mensal</b> dos <b>itens</b> é <b>maior</b> que o Valor <b>Mensal</b> informado.<br>" : "");
      textoFinal += (this.totalValorMensalItens < valorMensal ? "Total do Valor <b>Mensal</b> dos <b>itens</b> é <b>menor</b> que o Valor <b>Mensal</b> informado.<br>" : "");
      textoFinal += (this.totalValorGlobalItens > valorGlobal ? "Total do Valor <b>Global</b> dos <b>itens</b> é <b>maior</b> que o Valor <b>Global</b> informado.<br>" : "");
      textoFinal += (this.totalValorGlobalItens < valorGlobal ? "Total do Valor <b>Global</b> dos <b>itens</b> é <b>menor</b> que o Valor <b>Global</b> informado.<br>" : "");
      
      return textoFinal;
    }
  },
  methods: {
    itemContrato(index) {
      if (this.value.itens[index].idItemContrato === null) return null;
      return {
        id: this.value.itens[index].idItemContrato,
        descricao: this.value.itens[index].descricaoItemContrato
      }
    },      
    loaderItemContrato(page, search, id = null) {
      return findAllItemContrato(page, {
        nome: search,
        idTipo: id
      })
    },    
    addItem() {
      this.value.itens.push({
        id: null,
        idTipo: null,
        descricaoTipo: "",	
        idItemContrato: null,
        descricaoItemContrato: "",
        quantidade: 0,
        valUnitMensal: 0,
        valUnitGlobal:0,
      });
      this.configurarWatchers();
    },
    configurarWatchers() {
      // Limpa a lista de watchers
      this.watchers = [];
      // Configura um watcher para cada item
      this.value.itens.forEach((item, index) => {
        const watcher = this.$watch(
          () => item.idTipo,
          (newNome, oldNome) => {
            console.log(`Nome do item ${index + 1} alterado de ${oldNome} para:`, newNome);
            const refName = 'comboItemContrato_' + index;
            this.$refs[refName][0].resetValues();
            this.$refs[refName][0].search(newNome);          
          }
        );
        // Adiciona o watcher à lista de watchers
        this.watchers.push(watcher);
      });
    },
    removeItem(index) {
      this.watchers.forEach(unwatch => unwatch());
      this.value.itens.splice(index, 1);
      this.configurarWatchers();
    }    
  },
  mounted() {
    this.configurarWatchers();
  }
}
</script>

<style scoped>

</style>